import React from 'react';
import {
  ComponentPreview,
  DontDo,
  DontDoItem,
  Link,
  PageHero,
  PageNavigation,
  PageNavigationLink,
  PageWithSubNav,
  Paragraph,
  Section,
  SectionSubhead,
} from 'docComponents';
import pageHeroData from '../../../data/pages/patterns.yml';
import userlistsPreviewData from '../../../data/previews/user-lists.yml';

export default class IndexPage extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      styleSelect: 'large',
    };
  }

  handleScaleChange = e => {
    this.setState({
      styleSelect: e,
    });
  };

  render() {
    return (
      <PageWithSubNav pageType="design" title="User Lists" subnav="patterns">
        <PageHero heroData={pageHeroData} tierTwo="User Lists" hideHeader />
        <PageNavigation>
          <PageNavigationLink>Scale</PageNavigationLink>
          <PageNavigationLink>Usage</PageNavigationLink>
          <PageNavigationLink>Microcopy</PageNavigationLink>
        </PageNavigation>

        <Section title="Scale">
          <Paragraph>
            When choosing the{' '}
            <Link href="/components/avatars/design">avatar</Link> size for each
            user list, consider the interface as a whole and how it will fit in.
            Stick with small, medium and large.
          </Paragraph>
          <ComponentPreview
            name="PatternsContainer"
            layout="fullWidth"
            hideEnvironmentToggle
            previewData={userlistsPreviewData.scale}
            onChange={this.handleScaleChange}>
            {this.state.styleSelect === 'large' && (
              <img
                src={require('../../../imgs/previews/preview-user-lists-scale-large.png')}
              />
            )}
            {this.state.styleSelect === 'medium' && (
              <img
                src={require('../../../imgs/previews/preview-user-lists-scale-medium.png')}
              />
            )}
            {this.state.styleSelect === 'small' && (
              <img
                src={require('../../../imgs/previews/preview-user-lists-scale-small.png')}
              />
            )}
          </ComponentPreview>
        </Section>
        <Section title="Usage">
          <SectionSubhead>List Items</SectionSubhead>
          <Paragraph>
            Regardless if the list will display one user or more than fifty,
            each item should be identical. In some cases the list may grow over
            time, like when a user joins another team. There is no minimum list
            requirement to use this pattern, but be wise when displaying long
            lists.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="force the user to scroll through infinite lists to find who they’re looking for."
              img="user-list-items-dont"
            />
            <DontDoItem
              type="do"
              text="use search and scrolling to easily find people."
              img="user-list-items-do"
            />
          </DontDo>
          <SectionSubhead>Layout</SectionSubhead>
          <Paragraph>
            User lists should always be displayed vertically. Vertical space is
            abundant on both the web and mobile application, and scrolling is
            second nature. Don’t go against the grain and display user lists in
            unexpected layouts.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="format user lists in multi-column layouts."
              img="user-list-column-dont"
            />
            <DontDoItem
              type="do"
              text="divide items in long lists."
              img="user-list-column-do"
            />
          </DontDo>
        </Section>
        <Section title="Microcopy">
          <SectionSubhead>Hierarchy</SectionSubhead>
          <Paragraph>
            Each item in a user list should repeat the information in the same
            format, in proper hierarchy. Primary information should always
            appear first, then secondary, and finally supporting, nonessential
            information (if applicable).
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="organize content in a random order."
              img="user-list-hierarchy-dont"
            />
            <DontDoItem
              type="do"
              text="use nonessential text to support the information hierarchy."
              img="user-list-hierarchy-do"
            />
          </DontDo>
          <SectionSubhead>Limit Information</SectionSubhead>
          <Paragraph>
            Depending on the context of the list, it’s easy to accidentally cram
            in a lot of relevant information. Be intentional with what is
            displayed and choose to limit to the most important pieces of
            content.
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="overload the list item with all available content."
              img="user-list-limit-info-dont"
            />
            <DontDoItem
              type="do"
              text="pick and choose the most important pieces."
              img="user-list-limit-info-do"
            />
          </DontDo>
          <SectionSubhead>Content and Color</SectionSubhead>
          <Paragraph>
            Use Uniform text for all content in a user list. Make use of small
            and micro sizes, both in default and bold weight. To further
            emphasize each piece of information, stick to using Contrast,
            Default and Nonessential{' '}
            <Link href="/guidelines/colors/content/design">content colors</Link>
            .
          </Paragraph>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="mix and match text sizes."
              img="user-list-content-mix-dont"
            />
            <DontDoItem
              type="do"
              text="follow a consistent format for each."
              img="user-list-content-mix-do"
            />
          </DontDo>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="use one color for all elements in a list."
              img="user-list-one-color-dont"
            />
            <DontDoItem
              type="do"
              text="use content hierarchy to inform color choice."
              img="user-list-one-color-do"
            />
          </DontDo>
          <DontDo gridLayout="2">
            <DontDoItem
              type="dont"
              text="switch up content that the user can interact with."
              img="user-list-interaction-dont"
            />
            <DontDoItem
              type="do"
              text="keep interactions between items orderly."
              img="user-list-interaction-do"
            />
          </DontDo>
        </Section>
      </PageWithSubNav>
    );
  }
}
